/* eslint-disable  no-unused-vars */
import "./checkout-inpost.scss"
import React, { useEffect } from "react"

const CheckoutInpost = ({ setInpost, setInpostModal }) => {
  const isBrowser = typeof window !== "undefined"

  useEffect(() => {
    if (isBrowser) {
      window.easyPack.init({
        defaultLocale: "pl",
        mapType: "osm",
        searchType: "osm",
        filters: false,
        mobileSize: 768,
        points: {
          types: ["pop", "parcel_locker"],
        },
        map: {
          useGeolocation: true,
          initialTypes: ["pop", "parcel_locker"],
        },
      })
      window.easyPack.mapWidget("easypack-map", function (point) {
        setInpost(point)
        setInpostModal(false)
      })
    }
  }, [isBrowser, setInpost, setInpostModal])

  return (
    <>
      <button
        className="checkout__inpost-overlay"
        onClick={() => setInpostModal(false)}
      >
        Close
      </button>
      <div className="checkout__inpost">
        <div id="easypack-map"></div>
      </div>
    </>
  )
}

export default CheckoutInpost
