import React, { useState } from "react"
import { Helmet } from "react-helmet"
import { useQuery, useMutation } from "@apollo/client"
import { navigate } from "gatsby"
import scrollToElement from "scroll-to-element"
import { toast } from "react-toastify"
import NumberFormat from "react-number-format"

import PageSubHeader from "components/PageSubHeader/PageSubHeader"
import Input from "components/Input/Input"
import Textarea from "components/Textarea/Textarea"
import Checkbox from "components/Checkbox/Checkbox"
import CheckboxMark from "components/CheckboxMark/CheckboxMark"
import Button from "components/Button/Button"
import CheckoutInpost from "./CheckoutInpost/CheckoutInpost"

import validateAndSanitizeCheckoutForm from "validator/checkout"
import { getFormattedCart, getPaymentMethod } from "utils/functions"
import GET_CART from "queries/get-cart"
import UPDATE_SHIPPING_METHOD from "mutations/update-shipping-method"

const Checkout = () => {
  const initialState = {
    firstName: "",
    lastName: "",
    address1: "",
    address2: "",
    postcode: "",
    city: "",
    country: "PL",
    phone: "",
    email: "",
    customerNote: "",
    invoice: false,
    companyName: "",
    companyNIP: "",
    companyAddress1: "",
    companyAddress2: "",
    companyPostcode: "",
    companyCity: "",
    paymentMethod: "",
    shippingMethod: "",
    errors: null,
  }

  const [inpost, setInpost] = useState(null)
  const [inpostModal, setInpostModal] = useState(false)

  const inpostData = {
    inpost,
    setInpost,
    inpostModal,
    setInpostModal,
  }

  const [input, setInput] = useState(initialState)
  const [legal, setLegal] = useState(false)

  // Get Cart Data
  const { data, loading, refetch } = useQuery(GET_CART, {
    notifyOnNetworkStatusChange: true,
    onCompleted: ({ cart }) => {
      const updatedCart = getFormattedCart(cart)
      setInput({ ...input, shippingMethod: cart?.chosenShippingMethods?.[0] })
      localStorage.setItem("woo-next-cart", JSON.stringify(updatedCart))
    },
    onError: error => {
      console.warn(error)
    },
  })

  const [updateShippingMethod] = useMutation(UPDATE_SHIPPING_METHOD, {
    onCompleted: async ({ updateShippingMethod }) => {
      setInput({ ...input, paymentMethod: null })
      const id = updateShippingMethod?.cart?.chosenShippingMethods?.[0]
      await refetch()
      if (id === "flat_rate:10") {
        setInpostModal(true)
      } else {
        setInpost(null)
        localStorage.removeItem("woo-order-inpost")
      }
    },
    onError: error => {
      toast.error(error.message)
    },
  })

  const totalParsed = parseInt(data?.cart?.subtotal.match(/\d|\./g).join(""))
  const chosen_shipping_method = data?.cart?.chosenShippingMethods?.[0]
  const chosen_shipping_method_data =
    data?.cart?.availableShippingMethods?.[0]?.rates.find(
      ({ id }) => id === chosen_shipping_method
    )

  // Handle form submit.
  const handleFormSubmit = e => {
    e.preventDefault()
    const result = validateAndSanitizeCheckoutForm(input)
    if (!result.isValid) {
      setInput({ ...input, errors: result.errors })
      setInpost({ ...inpost })
      scrollToElement("#form")
      return
    }
    if (!input.paymentMethod) {
      toast.error(`Wybierz metodę dostawy`)
      return
    }
    if (input.shippingMethod === "flat_rate:10" && !inpost) {
      toast.error(`Wybierz paczkomat`)
      return
    }
    localStorage.setItem(
      "woo-order-summary",
      JSON.stringify({ ...input, shippingMethod: chosen_shipping_method })
    )
    localStorage.setItem("woo-order-inpost", JSON.stringify(inpost))
    navigate("/podsumowanie/")
  }

  // Handle onchange input.
  const handleOnChange = event => {
    const newState = { ...input, [event.target.name]: event.target.value }
    setInput(newState)
  }

  if (!loading && data?.cart?.isEmpty) {
    return (
      <p className="row__products-empty-info row__products-empty-info--margin">
        Aby złożyć <strong>zamówienie</strong> proszę dodać produkty do koszyka.
      </p>
    )
  }

  return (
    <>
      <Helmet>
        <script src="https://geowidget.easypack24.net/js/sdk-for-javascript.js" />
        <link
          rel="stylesheet"
          href="https://geowidget.easypack24.net/css/easypack.css"
        />
      </Helmet>
      <form onSubmit={handleFormSubmit} id="form">
        {inpostModal && <CheckoutInpost {...inpostData} />}
        <div className="row">
          <div className="col-lg-8">
            <PageSubHeader title="Dane odbiorcy i wysyłka" />

            <div className="row">
              <div className="col-sm-6">
                <Input
                  type="text"
                  placeholder="Imię"
                  name="firstName"
                  value={input.firstName}
                  onChange={handleOnChange}
                  errors={input.errors}
                  required
                />
              </div>
              <div className="col-sm-6">
                <Input
                  type="text"
                  placeholder="Nazwisko"
                  name="lastName"
                  value={input.lastName}
                  onChange={handleOnChange}
                  errors={input.errors}
                  required
                />
              </div>
              <div className="col-sm-6">
                <Input
                  type="text"
                  placeholder="Ulica"
                  name="address1"
                  value={input.address1}
                  onChange={handleOnChange}
                  errors={input.errors}
                  required
                />
              </div>
              <div className="col-sm-6">
                <Input
                  type="text"
                  placeholder="Numer domu"
                  name="address2"
                  value={input.address2}
                  onChange={handleOnChange}
                  errors={input.errors}
                />
              </div>
              <div className="col-sm-6">
                <Input
                  type="text"
                  placeholder="Kod pocztowy"
                  name="postcode"
                  value={input.postcode}
                  onChange={handleOnChange}
                  errors={input.errors}
                  required
                />
              </div>
              <div className="col-sm-6">
                <Input
                  type="text"
                  placeholder="Miejscowość"
                  name="city"
                  value={input.city}
                  onChange={handleOnChange}
                  errors={input.errors}
                  required
                />
              </div>
              <div className="col-sm-6">
                <Input
                  type="email"
                  placeholder="E-mail"
                  name="email"
                  value={input.email}
                  onChange={handleOnChange}
                  errors={input.errors}
                  required
                />
              </div>
              <div className="col-sm-6">
                <Input
                  type="phone"
                  placeholder="Telefon"
                  name="phone"
                  value={input.phone}
                  onChange={handleOnChange}
                  errors={input.errors}
                  required
                />
              </div>
            </div>

            <PageSubHeader
              className="page__sub-header--less-m"
              title="Dane do faktury"
            />
            <Checkbox
              name="invoice"
              value={input.invoice}
              onChange={e => {
                setInput({ ...input, [e.target.name]: !input.invoice })
              }}
            >
              Chcę fakturę VAT
            </Checkbox>
            {input.invoice && (
              <div className="row">
                <div className="col-sm-6">
                  <Input
                    type="text"
                    placeholder="Nazwa firmy"
                    name="companyName"
                    value={input.companyName}
                    onChange={handleOnChange}
                    errors={input.errors}
                  />
                </div>
                <div className="col-sm-6">
                  <Input
                    type="text"
                    placeholder="NIP"
                    name="companyNIP"
                    value={input.companyNIP}
                    onChange={handleOnChange}
                    errors={input.errors}
                  />
                </div>
                <div className="col-sm-6">
                  <Input
                    type="text"
                    placeholder="Ulica"
                    name="companyAddress1"
                    value={input.companyAddress1}
                    onChange={handleOnChange}
                    errors={input.errors}
                  />
                </div>
                <div className="col-sm-6">
                  <Input
                    type="text"
                    placeholder="Numer domu"
                    name="companyAddress2"
                    value={input.companyAddress2}
                    onChange={handleOnChange}
                    errors={input.errors}
                  />
                </div>
                <div className="col-sm-6">
                  <Input
                    type="text"
                    placeholder="Kod pocztowy"
                    name="companyPostcode"
                    value={input.companyPostcode}
                    onChange={handleOnChange}
                    errors={input.errors}
                  />
                </div>
                <div className="col-sm-6">
                  <Input
                    type="text"
                    placeholder="Miejscowość"
                    name="companyCity"
                    value={input.companyCity}
                    onChange={handleOnChange}
                    errors={input.errors}
                  />
                </div>
              </div>
            )}

            <PageSubHeader
              className="page__sub-header--less-m"
              title="Uwagi do zamówienia"
            />
            <div className="row">
              <div className="col-sm-12">
                <Textarea
                  rows="6"
                  placeholder="Wpisz tutaj swoje uwagi"
                  name="customerNote"
                  value={input.customerNote}
                  onChange={handleOnChange}
                  errors={input.errors}
                />
              </div>
            </div>

            <PageSubHeader
              className="page__sub-header--less-m"
              title="Sposób dostawy"
            />
            <div className="row">
              {data?.cart?.availableShippingMethods?.[0]?.rates?.map(
                ({ id, cost, label }, index) => (
                  <div
                    key={id}
                    className={`col-md-5${
                      index % 2 !== 0 ? " offset-md-1" : ""
                    }`}
                  >
                    <CheckboxMark
                      type="radio"
                      name="shippingMethod"
                      value={id}
                      checked={id === chosen_shipping_method}
                      onChange={() => {
                        updateShippingMethod({
                          variables: { input: { shippingMethods: id } },
                        })
                      }}
                    >
                      {label} {cost > 0 ? `(+${parseInt(cost)} zł)` : ""}{" "}
                      {id === "flat_rate:10" &&
                      chosen_shipping_method === "flat_rate:10" ? (
                        !inpost ? (
                          <button
                            className="btn-inpost"
                            type="button"
                            onClick={() => setInpostModal(true)}
                          >
                            Wybierz
                          </button>
                        ) : (
                          ` - ${inpost.name}`
                        )
                      ) : (
                        ""
                      )}
                    </CheckboxMark>
                  </div>
                )
              )}
            </div>

            <PageSubHeader
              className="page__sub-header--less-m"
              title="Metoda płatności"
            />
            <div className="row">
              {data?.paymentGateways?.nodes.map(({ id, title }, index) => (
                <div
                  key={id}
                  className={`col-md-5${index % 2 !== 0 ? " offset-md-1" : ""}`}
                >
                  <CheckboxMark
                    type="radio"
                    name="paymentMethod"
                    value={id}
                    checked={input.paymentMethod === id}
                    onChange={handleOnChange}
                  >
                    {title}
                  </CheckboxMark>
                </div>
              ))}
            </div>

            <PageSubHeader
              className="page__sub-header--less-m"
              title="Zgody formalne"
            />
            <Checkbox onChange={() => setLegal(!legal)}>
              *Chcę złożyć zamówienie w firmie MEMTECH Sp. z o.o. Sp. k. i
              akceptuję{" "}
              <a href="/regulamin/" target="_blank">
                regulamin
              </a>{" "}
              oraz{" "}
              <a href="/polityka-prywatnosci/" target="_blank">
                politykę prywatności
              </a>
              .
            </Checkbox>
            <Checkbox>
              Chcę otrzymywać informacje o najnowszych ofertach od firmy MEMTECH
              Sp. z o.o. Sp. k. i akceptuję{" "}
              <a href="/polityka-prywatnosci/" target="_blank">
                politykę prywatności
              </a>
              .
            </Checkbox>
          </div>

          <div className="col-xl-3 offset-xl-1 col-lg-4">
            <div className="order__summary">
              <div className="order__summary-info">
                <div className="row">
                  <div className="col-6">Kwota produktów:</div>
                  <div className="col-6">{data?.cart?.subtotal}</div>
                </div>
                <div className="row">
                  <div className="col-6">Koszt dostawy:</div>
                  <div className="col-6">
                    {chosen_shipping_method_data?.label}{" "}
                    {!!chosen_shipping_method_data?.cost &&
                      `(+${parseInt(chosen_shipping_method_data.cost)}zł)`}
                  </div>
                </div>
                {inpost && (
                  <div className="row">
                    <div className="col-6">Paczkomat:</div>
                    <div className="col-6">{inpost.name}</div>
                  </div>
                )}
                <div className="row">
                  <div className="col-6">Metoda płatności:</div>
                  <div className="col-6">
                    {getPaymentMethod(input?.paymentMethod)}
                  </div>
                </div>
              </div>
              <p>Łączna kwota zamówienia</p>
              <h3>
                <NumberFormat
                  value={data?.cart?.total}
                  displayType="text"
                  thousandSeparator=","
                  decimalSeparator="."
                  suffix=".00zł"
                />
              </h3>
              <div className="order__summary-shipping-info">
                <p>
                  {totalParsed < 2000 ? (
                    <>
                      Do darmowej wysyłki <br /> brakuje Ci{" "}
                      <NumberFormat
                        value={2000 - totalParsed}
                        displayType="text"
                        thousandSeparator=","
                        decimalSeparator="."
                        suffix=".00zł"
                      />
                    </>
                  ) : (
                    "Osiągnąłeś próg darmowej wysyłki!"
                  )}
                </p>
              </div>
              <Button type="submit" disabled={!legal}>
                Podsumowanie
              </Button>
            </div>
          </div>
        </div>
      </form>
    </>
  )
}

export default Checkout
