import "./checkbox-mark.scss"

import React from "react"

const CheckboxMark = ({
  children,
  name,
  checked,
  onChange,
  required,
  value,
  type,
  field,
}) => {
  return (
    <div className="checkbox-mark">
      <label className="checkbox-mark__label">
        <input
          type={type === "radio" ? "radio" : "checkbox"}
          name={name}
          checked={checked}
          value={value}
          onChange={onChange}
          required={required}
          {...field}
        />
        <span>
          {children}
          {required && <sup>*</sup>}
        </span>
      </label>
    </div>
  )
}

export default CheckboxMark
