import { gql } from '@apollo/client';

const UPDATE_SHIPPING_METHOD = gql`
    mutation($input: UpdateShippingMethodInput!) {
        updateShippingMethod(input: $input) {
            cart {
                chosenShippingMethods
            }
        }
    }
`;
export default UPDATE_SHIPPING_METHOD;