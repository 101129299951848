import "styles/pages/order.scss"

import React from "react"

import Layout from "components/layout"
import Seo from "components/seo"
import Breadcrumbs from "components/Breadcrumbs/Breadcrumbs"
import PageHeader from "components/PageHeader/PageHeader"
import Button from "components/Button/Button"
import OrderNav from "components/OrderNav/OrderNav"
import Checkout from "components/Checkout/Checkout"

const Order = () => {
  const title = "Dostawa i płatności"
  return (
    <Layout>
      <Seo title={title} />
      <Breadcrumbs title={title} />

      <section className="order-main">
        <div className="container-fluid">
          <PageHeader title={title} />
          <OrderNav step={2} />

          <Checkout />

          <div className="order__prev">
            <Button type="link" to="/koszyk/" className="button--bordered">
              Poprzedni krok
            </Button>
          </div>
        </div>
      </section>
    </Layout>
  )
}

export default Order
